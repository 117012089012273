<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="请选择" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2>隐私政策</h2>

		<p>
			导言<br />
			我们深知个人信息对您的重要性，将严格遵守法律法规要求采取相应的安全保护措施，致力于保障您的个人信息安全可控。在您使用PIX TECHNOLOGY PTE.
			LTD公司下的UniLive平台服务前，请您仔细阅读并充分理解本基本功能隐私政策（下称“本隐私政策”）以便您作出适当的使用选择。<br />
		</p>
		<p>
			本隐私政策仅适用于UniLive软件的服务，包括网站、网页、客户端及小程序以及随着技术发展出现的新的服务载体。
		</p>
		<p>
			如您对本隐私政策有任何疑问，您可以通过本隐私政策“如何联系我们”载明的方式与我们联系，我们将十分乐意为您解答。我们也会基于法律法规及业务发展变化，不时对本隐私政策进行修订，并以适当方式告知您。
		</p>
		<p>
			若您是已满14周岁未满18周岁的未成年人或者因智力、精神健康状况而不具有完全民事行为能力，请在您的法定监护人指导和陪同下共同阅读并同意本隐私政策；若您是未满14周岁的未成年人，请您的法定监护人为您阅读并同意本隐私政策。请您及您的法定监护人特别注意未成年人保护内容。
		</p>
		<h3>本隐私政策将帮助您了解以下内容</h3>
		<p>一、个人信息的收集和使用</p>
		<p>二、个人信息的共享、转移与公开</p>
		<p>三、个人信息的保护和存储</p>
		<p>四、您的权利</p>
		<p>五、未成年人保护</p>
		<p>六、隐私政策访问与更新</p>
		<p>七、如何联系我们</p>
		<h3>一、个人信息的收集和使用</h3>
		<p>
			UniLive提供不超过一定时长的视频搜索、播放的短视频服务，如您不同意本隐私政策，可点击“不同意”进入访客模式。
		</p>
		<p>
			我们也为您提供更多丰富的产品及/或服务。为实现您选择使用的功能及/或服务，遵守法律法规的要求，我们需要收集、使用您的相应个人信息和/或开启对应的必要权限。如您拒绝，可能导致您无法使用该功能及/或服务，但不影响其他非相关功能及/或服务的正常使用。您可以通过本隐私政策第四部分“您的权利”进行个人信息及权限管理。
		</p>
		<p>
			如您同意本基本功能隐私政策，仅代表您同意使用UniLive基本功能时，我们处理必要个人信息。如您使用UniLive其他扩展功能，我们会在您使用具体扩展功能时另行征求您的同意。
		</p>
		<p>
			在特定场景下，我们还会通过即时告知（弹窗、页面提示）、功能更新说明的方式，向您说明对应信息收集的目的、范围和使用方式。该类说明构成本隐私政策的一部分，并与本隐私政策具有同等效力。
		</p>
		<p>
			通常情况下，为实现以下UniLive平台功能及/或服务，会收集和使用您的个人信息或申请相关权限：
		</p>
		<p>
			1. 账号服务
		</p>
		<p>
			为使用我们丰富的功能及/或服务（评论、点赞、收藏、发布视频），您需要提供相关信息/进行相应授权以注册、登录UniLive平台。
		</p>
		<p>
			1.1电子邮箱：若您使用电子邮箱登录UniLive产品和服务，您需要提供电子邮箱地址、密码作为必要信息。
		</p>
		<p>
			2. 内容浏览、播放与搜索
		</p>
		<p>
			2.1 内容浏览与播放
			当您浏览、播放UniLive平台内容时，我们会记录您的设备信息（OAID、AndroidID、IDFA）及个人上网记录（浏览、点击、分享、收藏），以为您提供连续性、一致性的使用体验，保障服务质量。
		</p>
		<p>
			2.1.1位置相关的浏览：当您使用同城服务时，我们将向您申请授权开启位置权限以收集、使用您的精准地理位置信息。若您不予授权精准位置信息，可能会影响定位的准确性，我们将依赖粗略位置信息（WLAN接入点（SSID、BSSID）、蓝牙和基站、传感器信息、IP地址信息，该等信息与精准地理位置信息合称位置信息）以及您使用相关功能及/或服务中提供的位置信息（账号资料中自主填写的所在地、您上传内容中添加的位置链接）来判断您的大致位置，用于在您使用首页、精选、发现、关注功能时，向您展示与上述位置信息相关的内容及/或增值服务。
		</p>
		<p>
			2.1.2横屏播放：当您使用横屏模式时，我们需要调用您的加速度、重力、方向传感器，通过传感器信息检测设备屏幕方向变化，进行横竖屏切换。
		</p>
		<p>
			2.2 搜索
		</p>
		<p>
			当您使用搜索功能时，我们会收集您的设备信息（OAID、AndroidID、IDFA）、搜索指令（文本、语音或图片）及日志信息。为了提供高效的搜索服务，关键词信息会暂时存储在您的本地设备中，在您点击搜索图标时相应展示，您也可以选择全部或部分删除该等搜索历史记录。
			当您通过扫一扫识别二维码、搜商品，或进行图片搜索时，我们将向您申请授权开启相机、相册权限，方便您拍照或通过相册上传图片以实现搜索。在使用扫一扫时，我们将调用您的光线传感器以调整屏幕亮度，调用您的线性加速度传感器检测设备方向和角度信息，帮助您顺利完成识别扫描的内容。如果您不同意开启相关权限，不会影响您使用我们的基本功能。
		</p>


		<p>3. 社交互动</p>
		<p>
			我们为您提供了丰富的社交互动功能。为帮助您快速认识更多朋友、进行互动交流，我们将按照以下说明收集并使用相关信息。
		</p>
		<p>
			3.1 内容互动
		</p>
		<p>
			当您进行互动交流时，我们会收集和公开展示您的互动记录及内容（浏览、点赞、评论、关注、收藏、转发、分享，以及@其他用户）。
		</p>
		<p>
			在您分享或接收分享的内容、活动信息的情形下，UniLive需要访问您的剪切板，读取所包含的口令、分享码、链接，以实现跳转、分享、领取福利、活动联动服务，帮助用户打开相关内容，提升用户体验。我们仅在本地识别剪切板内容且仅当其属于UniLive指令时才会将其上传服务器。另外，在您分享或保存视频、图片时，我们需要读取您的相册。
		</p>
		<p>
			部分设备可能会提示应用正在读取您的剪切板或相册，但该等系统判断是不区分是本地处理还是上传，且由于不同设备统计标准的差异，存在误报的可能。如您有任何相关疑问，请与我们联系。
		</p>
		<p>
			3.2 消息通知
		</p>
		<p>
			您知悉并同意UniLive可能会基于您授权收集的手机号码、邮箱，通过短信、电话、电子邮件、系统通知，向您提供您可能感兴趣及/或必要的相关内容、活动、服务、功能信息，帮助您进行身份验证、安全验证、用户使用体验调研。如您不愿接受相关推送信息，您可以通过本隐私政策第四部分“您的权利”提供的路径进行相关设置，。
		</p>


		<p>4. 内容拍摄、制作与发布</p>
		<p>
			4.1 拍摄、制作
		</p>
		<p>
			当您使用短视频、直播工具拍摄、制作音视频、图片内容时，我们将向您申请授权开启相机权限以进行拍摄、录制，相册/存储权限（含SD卡）以实现相册上传内容及本地同步存储以防止作品丢失，麦克风权限以进行音频录制。请您知悉，我们只会上传您选中上传的音视频、图片内容，不会上传其它信息。同时，为了监测光线、调整亮度，实现拍摄画面的角度调整，我们将向您的设备调用光线传感器、加速传感器。在此过程中，您可以选择向我们提供全部或部分权限授权。如果您不同意开启相关权限，不会影响您使用我们的基本功能。
		</p>
		<p>
			4.2 发布
		</p>
		<p>
			当您公开发布图文、视频内容时，我们会收集相关内容并公开展示您的头像、昵称及上述内容信息。
		</p>
		<p>
			4.2.1快速上传：为了提升待发布作品的上传速度、丰富发布功能、优化体验以及节省流量，当您拍摄完成后点击“发布”按键之前，我们会将该内容在您的设备连接WLAN情况下开始上传并临时加载保存至我们的服务器。但请您知悉，该功能不会提前发布该等作品，只有当您点击“发布”按键才会提交发布。如您取消发布或因其他原因未成功上传，我们会立即删除相关内容。您可以通过本隐私政策第四部分“您的权利”提供的路径关闭该功能。
		</p>


		<p>5. 个性化推荐</p>
		<p>
			5.1 个性化推荐服务
			为了向您展示、推荐相关性更高的内容，提供更契合您要求的服务，我们会收集和使用您在使用UniLive平台服务过程中的浏览、播放、搜索记录，并结合依法收集的设备信息（OAID、AndroidID、IDFA）、日志信息、以及其他您授权的信息，基于您的偏好特征，向您推送您可能感兴趣的页面、视频、直播内容及搜索结果、可能认识的朋友、商业广告、服务、功能。如果您授权我们根据您的位置信息在首页、直播或商城页面进行个性化推荐，我们也会在相应功能中收集您的位置信息进行个性化推荐。

		</p>
		<p>
			5.2 个性化推荐运行机制
		</p>
		<p>
			个性化推荐会基于算法模型预测您的偏好特征，匹配您可能感兴趣的内容、服务或其他信息，对向您展示的该等信息进行排序。我们会根据您使用UniLive平台服务过程中的个人上网记录，对推荐模型进行实时反馈，不断调整优化推荐结果。为满足您的多元需求，我们会在排序过程中引入多样化推荐技术，拓展推荐的内容，避免同类型内容过度集中。
		</p>


		<p>6. 客户服务</p>
		<p>
			为向您提供客户服务，我们可能需要您提供必要的个人信息进行身份验证，以保证您的账号安全。为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会收集以下相关信息：联系方式（您与我们联系时使用的电话号码/电子邮箱或您向我们主动提供的其他联系方式），您的沟通信息（您与我们或与相关人员的文字/图片/音视频/通话记录），以及其他必要信息（账户信息、订单信息、您为了证明相关事实提供的其他信息）。
		</p>
		<p>
			为了提供服务及改进服务质量的合理需要，我们还可能使用您的以下相关信息：您与客服联系时提供的相关信息，参与问卷调查时向我们发送的问卷答复信息。
		</p>


		<p>7. APP适配与运营安全保障</p>
		<p>
			7.1为了向您提供优质而可靠的内容、服务，维护产品及功能的正常稳定运行，通过反作弊、反欺诈以保护您或其他用户或公众的安全及合法利益免遭侵害，更好地预防恶意程序、钓鱼网站、网络漏洞、计算机病毒、网络攻击、网络侵入安全风险，更准确地识别违反法律法规或UniLive平台相关规则的情况，我们需收集、使用和整合您的设备信息、日志信息、传感器信息以及其他您授权的信息（依法取得您授权后，自合作伙伴处收集的信息），用于判断账号安全、交易安全、进行身份验证、识别违法违规情况、检测及防范安全事件，并依法采取必要的记录、分析、处置措施。仅当用户行为触发风控条件时（多次输入密码错误、频繁异地登录、风险账号、支付金额异常、提现行为异常），我们才会访问您安装的应用信息（应用包名）或正在运行的进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源，并仅上传非常见App的包名信息。
		</p>
		<p>
			设备信息：设备标识信息（AndroidID、IDFA、IDFV、UAID（中国移动UAID、中国联通UAID及中国电信UAID，仅限安卓设备）、OAID、Openudid及其他综合设备参数和系统信息形成的设备标识符，不同的标识符在有效期、是否可由用户重置以及获取方式及不同系统方面会有所不同）、网络相关信息（蓝牙、IP地址、网络运营商、网络状态、类型、接入方式、网络质量数据）、设备参数信息（设备名称、设备型号）、软硬件操作系统信息（本应用程序版本、操作系统、语言、分辨率）。
		</p>
		<p>
			日志信息：操作、使用的个人上网记录（浏览、播放、搜索、点赞、关注、收藏、评论、下载、分享、交易）。
		</p>
		<p>
			请您理解，当您将UniLive切换到设备后台运行时，由于部分设备机型及系统原因，您此前使用UniLive相关功能而产生的信息收集行为，可能无法立即停止，从而导致短暂的后台信息收集行为。
		</p>
		<p>
			7.2为了提升运营质量与效率而进行研究、统计分析时，我们会对上述个人信息进行去标识化处理，不会与您的真实身份相关联。上述去标识化信息将用于改善UniLive平台的内容和布局，为商业决策提供产品或服务支撑，以及改进我们在平台上提供的产品与服务。
		</p>


		<p>8. 从第三方间接收集个人信息 </p>
		<p>
			我们不会主动从第三方获取您的个人信息。如为实现相关功能与服务需要从第三方获取您的个人信息，我们会确认第三方已经就相关个人信息获得您的授权，或者第三方依法被许可或被要求向我们披露您的个人信息。如果我们开展服务需进行的个人信息处理活动超出您原本向第三方提供个人信息时的授权范围，我们将征得您的明确同意。
		</p>


		<p>9. Cookie 及同类技术</p>
		<p>
			Cookie和同类设备信息标识技术是互联网中普遍使用的技术。当您使用我们的服务时，我们可能会使用相关技术向您的设备发送一个或多个Cookie或匿名标识符（下称“Cookie”），以收集、标识您使用UniLive平台服务时的信息。
			Cookie的使用主要是为了保障产品功能与服务的安全、高效运转，使我们确认您的账户与交易的安全状态，排查崩溃、延迟的相关异常情况；帮助您省去重复填写表单、输入搜索内容的步骤和流程，以获得更轻松的使用体验；同时，我们还可能利用上述技术为您推荐、展示、推送您感兴趣的内容，优化您对内容地选择与互动。我们承诺，不会将Cookie用于本隐私政策所述目的之外的任何其他用途。
			您可根据自己的偏好管理或删除Cookie。大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以在浏览器设置中操作清除Cookie数据，另外，您也可以清除软件内保存的所有Cookie。但清除后可能无法使用由我们提供的、依赖于Cookie的功能或服务。

		</p>
		<p>10. 第三方SDK及API技术</p>
		<p>
			部分功能和/或服务需要使用第三方SDK（或API）技术。请您知悉，这些第三方
			SDK（或API）可能会收集或使用您的部分个人信息。我们会对相关SDK（或API）进行严格的安全检测，并采取技术手段，努力确保其按照本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。另外，对我们与之共享用户信息的公司、组织和个人，我们也会与其签署协议来规范对于用户信息的使用。
		</p>
		<p>11. 征得同意的例外</p>
		<p>
			根据法律法规规定，在下列情形中，处理您的个人信息无需征得您的同意：<br />
			（1）为订立、履行您作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；<br />
			（2）为履行法定职责或者法定义务所必需；<br />
			（3）为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；<br />
			（4）为公共利益实施新闻报道、舆论监督行为，在合理的范围内处理您的个人信息；<br />
			（5）法律、行政法规规定的其他情形。

		</p>

		<h3>二、个人信息的共享、转移与公开</h3>
		<p>
			我们不会共享您的个人信息至第三方，除非为实现本隐私政策中声明的目的，在以下场景，我们需与关联公司或第三方合作伙伴（服务提供商、网络运营商、设备制造商及其他合作伙伴）共享您的个人信息。我们将遵守合法、正当、必要的原则，审慎评估合作伙伴的处理目的、安全保障能力，并根据法律规定签署相关协议。同时，我们会对合作伙伴进行严格地监督与管理，一旦发现其存在违规违约行为，将立即停止合作并追究其法律责任。
		</p>
		<p>相关合作场景以下：</p>
		<p>1. 为实现相关功能与服务</p>
		<p>1.1登录及社交服务：为向您提供第三方注册、登录及分享服务，我们的登录及社交服务合作伙伴（邮箱）可能会使用您的设备信息（OAID、AndroidID、IDFA）。</p>
		<p>1.2平台的第三方商家：为帮助您在第三方商家购买商品/服务、实现商品/服务的预约、展示第三方商家定制折扣信息，或者为您提供售后服务，我们会与平台的第三方商家共享您的订单和交易相关信息及/或账号标识信息。</p>
		<p>1.3支付服务：为保障交易安全、完成商品/服务的支付，我们合作的第三方支付机构可能需要处理您的订单信息、设备信息（OAID、AndroidID、IDFA）。</p>
		<p>1.4物流服务：为了准确、安全向您完成商品（您购买或参与活动领取的商品）的交付及配送，第三方物流公司在发货及配送环节不可避免地会获知您的相关配送信息。</p>
		<p>1.5消息通知：为与您使用的设备适配消息推送功能，设备制造商（华为、小米、OPPO、VIVO）会使用您的设备型号、版本及相关设备信息（OAID、AndroidID、IDFA）。</p>
		<p>1.6客户服务：为及时处理您的投诉、建议以及其他诉求，客户服务提供商需要使用您的账号及相关信息，以及时了解、处理和响应您的需求。如有必要，也可能需要使用您的手机号码或通过其他通讯方式与您取得联系。</p>
		<p>2. 广告</p>
		<p>
			基于我们的商业模式，我们会进行广告推送与投放。为了实现广告推送与投放，并有助于我们评估、分析、优化和衡量广告投放效果、有效性之目的，我们可能需要与广告主、服务商、供应商第三方合作伙伴（合称“广告合作伙伴”）共享您的部分信息与数据。我们会采用去标识化、匿名化或加密算法的技术措施对数据进行处理。
		</p>
		<p>
			2.1广告推送与投放：当我们进行广告推送与投放时，广告合作伙伴可能需要使用去标识或匿名化后的设备信息（OAID、AndroidID、IDFA）、标签信息，或者我们自广告合作伙伴处（运营商）使用去标识或匿名化后的设备信息（OAID、AndroidID、IDFA）、标签信息，并可能结合其合法收集的其他数据，优化与提升广告推送与投放的有效触达率。
		</p>
		<p>
			2.2广告统计分析：我们可能会向提供广告统计分析与优化服务的广告合作伙伴共享您的去标识或匿名化后的设备信息（OAID、AndroidID、IDFA）、标签信息、日志信息（广告点击、浏览、展示）、广告效果转化数据，用于评估、分析、监测和衡量广告推送与投放服务的有效性，以提供更合适您的广告内容；广告主及/或其委托的服务商可能要求我们共享前述部分或全部信息，用于进行用户来源的运营分析、优化广告投放策略或改进其产品与服务。
		</p>
		<p>2.3广告场景下的个人信息收集与提示：您在相关第三方广告推送和投放的落地页中可能需主动填写提交相应的个人信息（联系方式、地址，具体信息类型以页面显示为准），这些信息可能被广告主或其委托的广告合作伙伴收集处理。</p>
		<p>3. 实现安全与统计分析</p>
		<p>
			3.1保障使用安全：为了实现对您账号及/或财产安全的安全保障，提高我们及我们关联公司、合作伙伴服务的安全性，我们可能会与第三方合作伙伴共享必要设备信息（OAID、AndroidID、IDFA）、账号信息、日志信息，来综合判断您的账户及交易风险，避免遭受欺诈，预防安全事件的发生并依法采取必要的记录、审计及处置措施。
		</p>
		<p>3.2产品分析：为分析我们的产品及/或服务的稳定性和适配性，分析服务合作伙伴可能需要使用产品服务日志（产品崩溃、闪退的日志信息）、设备信息（OAID、AndroidID、IDFA）。</p>
		<p>（二）转移</p>
		<p>如我们因合并、分立、解散、被宣告破产需要转移个人信息的，会向您告知接收方的名称或者姓名和联系方式。我们会要求接收方继续履行本隐私政策约定义务。接收方变更原先的处理目的、处理方式的，其将重新向您征求同意。</p>
		<p>（三）公开</p>
		<p>我们不会主动公开您未自行公开的信息，除非遵循国家法律法规规定或者获得您的同意。</p>
		<p>（四）共享、转移、公开个人信息的例外</p>
		<p>根据法律法规规定，在下列情形中，共享、转移或公开您的个人信息无需征得您的同意：</p>
		<p>1.为订立、履行您作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；</p>
		<p>2.为履行法定职责或者法定义务所必需；</p>
		<p>3.为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</p>
		<p>4.为公共利益实施新闻报道、舆论监督行为，在合理的范围内处理个人信息；</p>
		<p>5.依照《个人信息保护法》的规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；</p>


		<h3>三、个人信息的保护和存储</h3>
		<p>（一）信息安全体系保证</p>
		<p>
			为保障您的信息安全，我们努力采取各种符合业界标准的合理的物理、电子和管理方面的安全措施（SSL、信息加密存储、数据中心的访问控制）来保护您的信息，使您的信息不会被泄漏、毁损或者丢失。我们有行业先进的以数据为核心、围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术、个人信息保护影响评估多维度提升UniLive服务系统的安全性。
		</p>
		<p>（二）信息安全管理制度</p>
		<p>我们建立了专门的安全部门、安全管理制度、数据安全流程保障您的个人信息安全。我们采取严格的数据使用和访问制度，确保只有授权人员才可以访问您的个人信息。我们会定期对人员进行安全教育和培训，并适时对数据和技术进行安全审计。</p>
		<p>
			同时，我们还设立了UniLive安全应急响应中心，由专业的安全技术和运营团队负责维护，便于及时有效地响应、处置各类安全漏洞和突发事件，联合相关部门进行安全事件溯源和打击。一旦发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。事件相关情况我们将以邮件、信函、电话、推送通知的方式告知您。难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照相关监管部门要求，上报数据泄露等安全事件的处置情况。
		</p>
		<p>（三）账号安全风险防范</p>
		<p>
			在使用某些服务时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息（电话、银行账户信息）。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供，不轻信通过第三方产品或服务进行交易，以免信息被窃取甚至遭遇电信网络诈骗。
		</p>
		<p>（四）信息存储</p>
		<p>1. 存储地点</p>
		<p>UniLive会按照法律法规的规定，将在中国境内运营过程中收集和产生的个人信息存储于中国境内。如您的个人信息存储地点从中国境内转移到境外，我们将严格依照法律规定执行或获得您的单独同意。</p>
		<p>2. 存储期限</p>
		<p>一般而言，我们仅在为实现目的所必需的时间及法律法规所规定的保存期限内保留您的个人信息，超出上述存储期限后，我们会对您的个人信息进行删除或匿名化处理。</p>
		<p>如UniLive平台终止服务或运营，我们会提前将停止运营的通知以送达或公告的形式通知您，并在终止服务或运营后合理的期限内删除您的个人信息或进行匿名化处理。</p>

		<h3>四、您的权利</h3>
		<p>
			我们非常重视您作为个人信息主体所享有的权利，并尽全力保护您对您个人信息的控制权。当完成UniLive账号的注册、登录后，您可以通过以下方式行使相关权利（各关联产品的权利行使路径可能不一致，以实际情况为准），我们会在符合法律法规要求的情形下响应您的请求。同时您也可以通过本隐私政策“如何联系我们”载明的方式联系我们行使您的相关权利。
		</p>
		<p>（一）查阅、复制、转移个人信息</p>
		<p>您可以通过UniLive进行个人信息的查阅、复制、转移：</p>
		<p>（二）更正、补充个人信息</p>
		<p>当您发现我们处理的关于您的个人信息不准确或者不完整时，经对您的身份进行验证后，您可以通过反馈与报错的方式将您的更正或补充申请提交给我们。</p>
		<p>一般情况下，您可随时更正、补充自己提交的信息，但出于安全性和身份验证（邮箱申诉服务）的考虑，注册时提供的某些初始注册信息及验证信息不在可更改之列。</p>
		<p>（三）删除个人信息</p>
		<p>您可以通过上述“（二）更正、补充个人信息”列明的路径删除您的部分信息，或申请注销账户删除您的全部信息。</p>
		<p>在以下情形中，您还可以向我们提出删除个人信息的请求：</p>
		<p>1. 处理目的已实现、无法实现或者为实现处理目的不再必要；</p>
		<p>2. 停止提供产品或者服务，或者保存期限已届满；</p>
		<p>3. 您撤回同意；</p>
		<p>4. 违反法律法规或者违反约定处理个人信息；</p>
		<p>5. 法律法规规定的其他情形。</p>
		<p>当您从我们的服务中删除信息后，因为适用的法律和安全技术限制，我们可能不会立即从备份系统中删除相应的信息，我们将停止除存储和采取必要的安全保护措施之外的处理，直到备份可以清除或实现匿名化。</p>
		<p>（四）撤回同意及更改权限</p>
		<p>
			请您理解，每个功能及/或服务需要一些基本的个人信息才能得以完成，当您撤回同意后，我们无法继续为您提供撤回同意所对应的功能及/或服务，也不再处理您相应的个人信息。但您撤回同意的决定，不影响撤回前基于个人同意已进行的个人信息处理活动的效力。
		</p>
		<p>（五）注销账号</p>
		<p>
			您可以通过申请注销您的账号。为确保是相应用户或者该用户授权的主体申请注销账号，UniLive将收集和验证用户和申请人的身份信息，尽最大努力保护用户的权益。UniLive严格遵守法律法规有关个人信息保护的规定，以上信息仅用于本次账号注销。
		</p>
		<p>UniLive特别提示，您注销账号的行为是不可逆的。除法律法规另有规定外，在您注销账号后，我们将删除或匿名化有关您的个人信息，若删除个人信息从技术上难以实现的，我们会停止除存储和采取必要的安全保护措施之外的处理。</p>
		<p>（六）逝者账号权益</p>
		<p>
			如UniLive用户不幸逝世，未注销的账号在使用权人去世后可成为悼念账号，UniLive仍将继续保护悼念账号中用户个人信息的安全。逝者近亲属可以通过本隐私政策“如何联系我们”载明的方式联系我们，在不违反逝者生前安排且不侵害他人和公众正当利益的前提下，其可在完成身份核验手续后，查阅、复制、更正、删除逝者个人信息以行使法律法规规定的合法、正当权益。
		</p>


		<h3>五、未成年人保护</h3>
		<p>
			我们根据国家相关法律法规的规定保护未成年人的个人信息，只会在法律允许、父母或其他监护人明确同意或保护未成年人所必要的情况下收集、使用、共享、转移与公开未成年人的个人信息。如我们发现在未事先获得可证实的父母或其他监护人同意的情况下收集了未成年人的个人信息，会删除相关信息。同时我们建立了严格的未成年人信息收集使用规则，以保护儿童（未满14周岁）和青少年（已满14周岁但未满18周岁）的个人信息安全。
			若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，可以通过本隐私政策“如何联系我们”载明的方式联系我们。
		</p>


		<h3>六、隐私政策访问与更新</h3>
		<p>（一）访问本隐私政策</p>
		<p>1.我们将在您首次注册UniLive账号时，向您提示本隐私政策并由您自主作出是否同意的决定。</p>
		<p>（二）本隐私政策更新</p>
		<p>1.UniLive可能会适时对本隐私政策进行修订 ，未经您明确同意或者符合法律法规的相关规定 ，我们不会削减您按照本隐私政策所应享有的权利。</p>
		<p>2.在UniLive隐私政策更新后，UniLive会在您登录时以站内信或其他适当的方式向您展示最新版本，以便您及时了解本隐私政策的最新内容。</p>
		<p>3.对于重大变更，我们会提供显著的通知（邮箱、私信、系统通知、弹窗提示）。</p>

		<h3>七、如何联系我们</h3>
		<p>我们设立了专门的个人信息保护团队和负责人。如您对个人信息保护相关事宜有任何疑问、投诉、意见或建议，可以通过以下任一方式与我们联系：</p>
		<p>1. 发送邮件至：unilive.team@gmail.com</p>
		<p>UniLive将尽快审核所涉问题，并在验证您的身份后及时予以回复。一般情况下，我们将在十五个工作日内回复您的请求。</p>



	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					value: '中文',
					label: '中文'
				}, {
					value: 'English',
					label: 'English'
				}, {
					value: '한국인',
					label: '한국인'
				}, {
					value: '日本語',
					label: '日本語'
				}, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
			],
			value: '',
			language: ''
		}
	},
	created() {
		// url获取参数
		this.language = this.$route.query.language;
		if (this.language == 'zh_CN') {
			this.$router.push({
				path: '/PrivacyPolicyZh'
			})
		}
		if (this.language == 'en_US') {
			this.$router.push({
				path: '/PrivacyPolicyEn'
			})
		}
		if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/PrivacyPolicyKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/PrivacyPolicyJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/PrivacyPolicyRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/PrivacyPolicyVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/PrivacyPolicyTh'
			})
		}
	},
	methods: {
		clickChange(value) {
			this.value = value;
			if (value == '中文') {
				this.$router.push({
					path: '/PrivacyPolicyZh'
				})
			}
			if (value == 'English') {
				this.$router.push({
					path: '/PrivacyPolicyEn'
				})
			}
			if (value == '한국인') {
				this.$router.push({
					path: '/PrivacyPolicyKo'
				})
			}
			if (value == '日本語') {
				this.$router.push({
					path: '/PrivacyPolicyJa'
				})
			}
			if (value == 'Русский') {
				this.$router.push({
					path: '/PrivacyPolicyRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/PrivacyPolicyVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/PrivacyPolicyTh'
				})
			}
		},
	}
}
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}


h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}
</style>